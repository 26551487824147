<template>
	<banner-style1 :position="5"></banner-style1>
	<div class="work-details ul-none">
		<div class="con c w">
			<nav>
				<ul>
					<li v-for="item in list.data" :key="item.value">
						<span :class="{ on: item.value === listCurrent }"
							@click="navClick(item.value)">{{ item.label }}</span>
					</li>
				</ul>
			</nav>
			<div class="win-award c w">
				<ul>
					<li @click="toPage(item.id)" v-for="item in listData.data" :key="item.id">
						<div class="d-img">
							<img :src="item.thumbnail" alt="">
						</div>
						<div class="d-con">
							<div class="text">
								<p class="time">{{item.createTime}}</p>
								<h2>{{item.title}}</h2>
								<p class="desc over-text2">{{item.describe}}</p>
							</div>
							<el-icon class="arrow-right" size="30px">
								<ArrowRight />
							</el-icon>
						</div>
					</li>
				</ul>
			</div>
			<div class="no-more" v-if="!listData.data.length">暂无数据</div>
		</div>
	</div>
</template>

<script setup>
	import {
		reactive,
		ref
	} from "vue";
	import {
		getList
	} from '@/api/competition'
	import {
		useRouter
	} from 'vue-router'
	import {
		getZqDictDetail
	} from '@/api/publish'
	import winAward from '@/components/winAward'
	let router = useRouter()

	function toPage(id) {
		router.push({
			path: '/review/detail',
			query: {
				id,
			}
		})
	}
	const bannerList = [{
		img: require("@/assets/home/adv1.png"),
	}, ];

	const listCurrent = ref(1);
	const list = reactive({
		data: []
	});
	const listData = reactive({
		data: []
	})
	getZqDictDetail({
		dictId: 5
	}).then(res => {
		list.data = res.data
		listCurrent.value = res.data[0].value

		getList({
			type: 4,
			sessionNumber: res.data[0].value
		}).then(res => {
			listData.data = res.data.data
		})
	})

	function navClick(i) {
		listCurrent.value = i;
		getList({
			type: 4,
			sessionNumber: i
		}).then(res => {
			listData.data = res.data.data
		})
	}
</script>

<style lang="scss" scoped>
	.work-details {
		padding-top: 40px;

		.con {
			display: flex;
		}

		nav {
			font-weight: 700;
			box-sizing: border-box;
			margin-bottom: -55px;

			ul {
				width: 120px;

				li {
					flex: 1;
					margin-bottom: 10px;

					span {
						display: inline-block;
						cursor: pointer;
					}
				}

				.on {
					color: #59ad96;
					border-bottom: 3px solid $themeColor;
				}
			}
		}
	}

	.con {

		.con-warp {
			border-top: 1px solid #e5e5e5;
			margin: 40px 0;
		}

		.content-html {
			padding: 20px 0;
		}
	}

	.no-more {
		height: 50vh;
		font-weight: 700;
		font-size: 30px;
		color: #ccc;
		text-align: center;
		padding-top: 100px;
	}

	.win-award {
		padding-bottom: 80px;
		width: 100%;

		.d-img {
			width: 330px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		li {
			position: relative;
			display: flex;
			height: 140px;
			padding-bottom: 20px;
			border-bottom: 1px dashed #e5e5e5;
			cursor: pointer;

			.d-con {
				margin-left: 40px;
				flex: 1;

				.text {
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding-right: 40px;
				}

				.time {
					margin-bottom: 5px;
					color: #a0a0a1;
				}

				.desc {
					margin-top: 3px;
					color: #a0a0a1;
				}

				.arrow-right {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 0;
				}
			}
		}
	}
</style>
